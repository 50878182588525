<template>
  <div>
    <h3>Progresso DNA campeão</h3>
    <b-row class="d-flex justify-content-between mt-2">
      <b-col cols="12" md="6">
        <b-card class="campaign-card">
          <div class="d-flex justify-content-center h-100 w-100">
            <div class="progression">
              <div class="title">Total anual</div>
              <div class="step-progress">
                <div class="progress-container mr-2">
                  <div class="bar" :style="{ width: progress + '%' }">
                    <span>{{ progress > 10 ? progress + '%' : '' }}</span>
                  </div>
                </div>
                <span class="points">{{ points }}/{{ maxPoints }}</span>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as kpiTypes from "@/modules/kpi/store/types";
import { isPnRole } from "@/constants/auth";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  data() {
    return {
      progress: 0,
      maxPoints: 0,
      points: 0
    };
  },
  setup() {
    return { toast: useToast() };
  },
  computed: {
    isPn: function () {
      return isPnRole(this.user?.user_role_id);
    },
  },
  methods: {
    ...mapActions({
      getDnaAnualProgress: kpiTypes.GET_DNA_ANUAL_PROGRESS
    }),
  },
  mounted() {
    this.getDnaAnualProgress()
      .then((resp) => {
        this.progress = resp.data.progress;
        this.maxPoints = resp.data.max_points;
        this.points = resp.data.points;
      })
      .catch((err) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os dados da campanha. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
    })
  }
};
</script>
<style scoped lang="scss">
.campaign-card {
  width: 100%;
  height: 140px;
  display: flex;
}

.progression {
  width: 100%;

  .title {
    font-weight: 600;
    margin: 10px 0;
  }

  .step-progress {
    display: flex;

    .progress-container {
      width: 85%;
      background-color: #293045;
      border-radius: 10px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      height: 20px;

      .bar {
        height: 100%;
        background-color: #02f518;
        color: #293045;
        border-radius: 10px;
        transition: width 0.5s ease;
        text-align: center;
        font-weight: 600;
      }
    }
  }

  .points {
    text-align: center;
    font-weight: 600;
  }
}

</style>